.radius {
  border-radius: 1rem;
}

.btn {
  background-color: #29abe2;
  width: 100%;
  border-radius: 10px;
}

.btn2 {
  background-color: #add8e6;
  width: 80%;
  border-radius: 10px;
  border: none;
  height: 2.6rem;
}

.modal-backdrop {
  height: 100%;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.modal-content {
  border-radius: 1rem;
  border: 0;
  width: 90%;
  /* margin-left: 5%; */
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 7%), 0 4px 6px -2px rgb(0 0 0 / 5%);
}

.questionWithSubQuestionsImage {
  width: 100%;
}
