@font-face {
  font-family: 'Brandon-Grotesque-Regular';
  src: local('Brandon Grotesque'),
    url('./font/brandon-grotesque-font-family/BrandonGrotesque-Regular.ttf')
      format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: local('Brandon Grotesque'),
    url('./font/brandon-grotesque-font-family/Brandon_bld.otf')
      format('opentype');
  font-style: bold;
}

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Brandon Grotesque' !important;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-view {
  max-width: 500px;
  margin: 0 auto;
}

.module6-custom-description {
  font-style: normal !important;
}

.sv-progress__text {
  display: none !important;
}

.smallfont {
  font-size: 12px !important;
}

.smallfont b {
  font-size: 12.5px !important;
}

.smallfont h1 {
  font-size: 17px !important;
}

.smallfont h2 {
  font-size: 16px !important;
}

.smallfont h3 {
  font-size: 15px !important;
}

.smallfont h4 {
  font-size: 14px !important;
}

.smallfont h5 {
  font-size: 13.5px !important;
}

.smallfont .dc-homepage-body-summary-data {
  font-size: 13px !important;
}

.bigfont {
  font-size: 14px !important;
}

.bigfont b {
  font-size: 14px !important;
}

.bigfont h1 {
  font-size: 20px !important;
}

.bigfont h2 {
  font-size: 18px !important;
}

.bigfont h3 {
  font-size: 18px !important;
}

.bigfont h4 {
  font-size: 18px !important;
}

.bigfont h5 {
  font-size: 16px !important;
}

.bigfont .dc-patient-details-box p {
  font-size: 15px !important;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

p {
  font-size: inherit;
}

:root {
  /* patient css */
  --pa-primary: #f9df81;
  --pa-primary-light: rgba(240, 224, 191, 0.6);
  --pa-secondary: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  --pa-tertiary: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
  --pa-light: #d9d9d9;
  --pa-border: #184788;
  --pa-primary-green: #67d841;
  /* dietitian css */
  --di-primary: #144284;
  --di-secondary: #008cfd;
  --di-tertiary: #5bc1e7;
  /* doctor css */
  --do-primary: #184788;
  --do-secondary: linear-gradient(111.59deg, #468ff3 -4.37%, #184788 84.87%);
  --do-tertiary: linear-gradient(108.31deg, #184788 -3.91%, #468ff3 59.53%);
  --do-light: rgba(193, 219, 254, 0.5);
}

a.poster {
  background: var(--pa-primary);
  border-radius: 10px;
  width: 50%;
  padding: 0.3rem;
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  color: #000;
  font-weight: bold;
  text-decoration: none !important;
}

#disclaimer-title-text {
  font-size: 15px !important;
  font-family: 'Brandon Grotesque';
}

.line-height-18px {
  line-height: 18px;
}

.sv_qstn .sq-root {
  border-radius: 29px;
  padding: 7px;
  /* background-color: #d5bf99; */
}

.sq-label {
  text-align: left;
}

.sq-root {
  text-align: center;
}

.hide-input {
  display: none;
}

.sq-root-bool {
  background-color: transparent !important;
}
.sq-root-text {
  text-align: justify !important;
}

.sq-title {
  font-size: 1rem !important;
  font-weight: bolder;
}

.sv_action_description {
  text-align: center !important;
  font-style: italic;
}

.sv_action_description-m5 {
  font-style: normal;
  margin-top: 0.5rem;
}

.sq-root-rating {
  background-color: #fff !important;
}

.sv-root-modern .sv-question__title--answer {
  color: #000 !important;
  background-color: transparent !important;
}

.sv-panel__icon {
  background-color: red;
}
.main_root {
  background-color: #fff !important;
  padding-bottom: 1rem;
  border-radius: 15px;
  margin-bottom: 0px;
  margin-top: -2rem;
}

.override-main-rating .override-root-rating {
  border-radius: 15px;
  padding: 7px;
  background-color: #d5bf99 !important;
  text-align: center;
}

.override-main-rating-m7 .override-root-rating-m7 {
  border-radius: 15px;
  padding: 7px;
  background-color: #ffffff !important;
  text-align: center;
}

.sv_qstn-action {
  margin-top: -1rem;
  border-radius: 10px;
  background-color: #ffffff;
}

.btn-primary-golden {
  background: #b5893f;
  border-radius: 15px;
}

.sv_description {
  text-align: center;
  color: #000 !important;
}

.sv-footer__prev-btn {
  width: 100%;
  text-align: center !important;
  margin-top: -3rem;
  border-radius: 5px !important;
  border: 1px solid var(--pa-primary) !important;
  background-color: transparent !important;
  color: #d5ab17 !important;
  margin-right: 0px !important;
}

.sv-footer__next-btn {
  text-align: center;
  background: var(--pa-primary) !important;
  border-radius: 8px !important;
  width: 100% !important;
  color: #202020 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-right: 0px !important;
}

.sv-footer__complete-btn {
  background-color: var(--pa-primary) !important;
  text-align: center !important;
  width: 100%;
  border-radius: 5px;
  margin-right: 0px !important;
  color: #202020 !important;
}

img[alt='image'] {
  height: 100px;
  object-fit: contain;
  text-align: center;
}

.questionWithSubQuestionsImage {
  width: 100%;
  height: 100px;
  object-fit: contain;
  text-align: center;
}

.sq-item:nth-child(1) {
  margin-bottom: 5px;
}
.sq-root-action {
  text-align: center;
}
.sq-root-m5 {
  display: none;
}

.navigation-bar {
  padding: 1em;
}

.sv_action_description-m7 {
  font-family: 'Brandon Grotesque';
  line-height: 1.2rem;
  font-weight: 400;
}

.sv-row,
.sv-clearfix,
.sv-string-viewer {
  font-family: 'Brandon Grotesque';
}

.sv-question,
.sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question,
.sv-root-modern
  .sv-container-modern
  .sv-body
  .sv-page
  .sv-row
  .sv-row__question {
  background-color: #fcfcfc !important;
}

.sv-question {
  overflow: visible;
}

.sv-root-modern .sv-checkbox--checked .sv-checkbox__svg {
  fill: #000 !important;
  background-color: var(--pa-primary) !important;
  border: none !important;
  border-radius: 5px !important;
}

.sv-checkbox__svg {
  fill: #fff !important;
  background-color: var(--pa-light) !important;
  border: none !important;
  border-radius: 5px !important;
}

.form-check-input {
  fill: #fff !important;
  background-color: var(--pa-light) !important;
  border: none !important;
  border-radius: 5px !important;
}

.form-check-input:checked {
  fill: #fff !important;
  background-color: var(--pa-primary) !important;
}

.sv-root-modern .sv-checkbox--allowhover:hover .sv-checkbox__svg {
  fill: #fff !important;
  background-color: var(--pa-light) !important;
  border: none !important;
  border-radius: 5px !important;
}
.sv-root-modern .sv-checkbox--allowhover .sv-checkbox__svg {
  fill: #fff !important;
  background-color: var(--pa-light) !important;
  border: none !important;
  border-radius: 5px !important;
}

/* In your CSS file, e.g., App.css */
.sv_progress_bar_block {
  display: flex;
  justify-content: space-between;
  height: 5px;
  width: 100%;
}

.green-background {
  background-color: #67d841;
  width: 100%;
}

.gray-background {
  background-color: #d9d9d9;
  width: 100%;
}

.lowercase-text {
  text-transform: lowercase;
}

.progress-bar {
  background-color: #00b74a !important;
}

.sv-root-modern .sv-rating__item--selected .sv-rating__item-text {
  background-color: #f9df81 !important;
  font-size: 1rem;
  color: #000 !important;
}

.sv-rating__item-text {
  font-size: 1rem;
  color: #fff !important;
  border: none !important;
  background-color: #d9d9d9 !important;
  border-radius: 10px !important;
  margin-right: 0.8rem !important;
}
.sv-rating__max-text {
  margin: 0 !important;
}
.sv-rating__min-text {
  margin-right: 5px !important;
}
/* .sv-question__header--location--top {
  display: flex !important;
  align-items: center !important;
} */

.sv-root-modern .sv-description {
  color: #000 !important;
}

.sv-dropdown,
.sv-dropdown:focus {
  background-image: url('./images/DLP_revamp_asset/Icon/chevron-icon.svg') !important;
  background-size: 1.5em 100% !important;
}

.bg {
  background-image: url(./images/DLP_revamp_asset/background-img/backgroung-pattern-top.png),
    url(./images/DLP_revamp_asset/background-img/background-pattern-bottom.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left bottom;
  background-size: 50% 22%, contain;
}

.main-landing-page {
  border-radius: 20px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 10px;
}

.each-logo-box {
  box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
}

video {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 14px;
}

.intro-header-navbar {
  width: 100%;
  position: relative;
  z-index: 100;
}

.intro-header-navbar p {
  margin: 0;
}

.intro-header-navbar-body {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px !important;
  font-weight: bold;
}

.intro-header-navbar-language p:first-of-type,
.intro-header-navbar-fontsize p:first-of-type {
  margin-right: 10px;
}

.intro-header-navbar-fontsize p:last-of-type {
  font-size: 18px !important;
  margin-bottom: -2.5px;
}

.intro-header-navbar-language,
.intro-header-navbar-fontsize {
  justify-content: center !important;
  align-items: flex-end !important;
}

.user_profile {
  color: #184788;
}
.blue-text {
  color: #184788;
}

.user_image {
  width: 4rem;
}

.name {
  margin-left: 2rem;
}

.sv-selectbase__decorator.sv-item__decorator:focus {
  outline: none !important;
}

.sv-question__content {
  font-size: 13px !important;
  font-weight: 900 !important;
}
.sv-string-viewer img {
  font-weight: 900 !important;
  max-width: 150px !important;
  object-fit: contain;
}

.sv-title .sv-question__title .sq-title {
  font-weight: bold !important;
  font-size: 1.5rem !important;
}

.sv-title {
  font-family: 'Brandon Grotesque' !important;
}

.sv-question__title {
  text-align: center !important;
}

.sv_action_description {
  font-size: 13.5px;
}

.whereby-video {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 14px;
}

.small-screen-center {
  max-width: 550px !important;
  margin: 0 auto !important;
}

.button-small-screen {
  width: 30% !important;
}

.normal-line-height {
  line-height: 16px;
}

.xl-line-height {
  line-height: 20px;
}

.sv-question__description {
  font-size: 0.9rem;
}

.primary-button {
  background: #b5893f !important;
  border-radius: 15px !important;
  width: 100%;
  font-size: 16px !important;
}

.primary-button-bg-white {
  background: #fff !important;
  border-radius: 15px !important;
  width: 100%;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.primary-button-bg-white-2 {
  background: #fff !important;
  border-radius: 15px !important;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.primary-button-rewards {
  background: #b5893f !important;
  font-size: 14px !important;
  min-width: 120px;
  border-radius: 6px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
}
.cancel-button-rewards {
  font-size: 14px !important;
  min-width: 120px;
  border-radius: 6px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
}

.primary-button-bg-gray-rewards {
  background: #c7c5c5 !important;
  color: #b5893f !important;
  font-size: 16px !important;
  border: 1px solid #b5893f !important;
}

.reward-box-bg {
  background: #f3f3f3;
  border: 1px solid #efefef;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

/* font new ui*/

.font-main-header-bold {
  font-size: 20px;
  font-weight: 900;
  line-height: 2;
}

.font-secondary-header-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
}

.font-img-header-bold {
  font-size: 18px !important;
  font-weight: 800;
  line-height: 1.5;
}

.font-normal-header-bold {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8;
}

.font-normal-navbar-bold {
  font-size: 0.8rem;
  font-weight: 700;
}

.font-normal-navbar-bold.active {
  color: #d5ab17;
}

.font-normal-line {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
}

/* font new ui */

.font-large-bold {
  font-size: 18px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;
}

.golden-text {
  color: #b5893f;
}

.header-yellow-text {
  font-size: 1.4rem;
  color: #b5893f;
  font-weight: 900;
}

.main-wrapper-non-patient {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-wrapper-div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
}

.main-body-content {
  height: auto;
  margin: 0 7%;
}

.main-body-height {
  flex-grow: 1;
  margin: 0;
  min-height: 65vh;
}

.main-body-content-page {
  flex-grow: 1;
  margin: 0 7%;
}

.main-img-banner {
  display: flex;
  justify-content: center;
  margin: 0px 5px;
  width: 100%;
  height: auto;
}

.main-img-banner img {
  object-fit: contain;
}

.footer-navi-button {
  height: auto;
  margin: 0 7%;
}

.main-header {
  height: auto;
}

.navi-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #184788;
  padding: 10px 0px;
  border-radius: 10px;
  color: #184788;
}

.navi-button-font {
  font-size: 1rem;
  font-weight: 700;
}

.logo-homepage {
  width: 25% !important;
  height: auto;
  margin-top: 10px;
  margin-bottom: 30px;
}

.primary-button-border-gold {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  color: #d5ab17;
  font-size: 16px;
  border: 1px solid #d5ab17;
  font-weight: 700;
  padding: 0px 20px;
}

.custom-primary-button {
  background: #f9df81 !important;
  border-radius: 8px !important;
  width: 100% !important;
  color: #202020 !important;
  font-size: 1rem !important;
  border: none !important;
  font-weight: 700 !important;
  padding: 10px 20px !important;
}

.custom-secondary-button {
  background: #f9df81 !important;
  border-radius: 10px !important;
  width: 100% !important;
  color: #202020 !important;
  font-size: 1rem !important;
  border: none !important;
  font-weight: 700 !important;
  padding: 2px 20px !important;
}

.custom-transparent-button {
  width: 100%;
  text-align: center !important;
  border-radius: 10px !important;
  border: 1px solid var(--pa-primary) !important;
  background-color: transparent !important;
  color: #d5ab17 !important;
  padding: 7px 20px !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.custom-fill-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.date-input-wrapper {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #777;
  transform: translateY(-50%);
  cursor: pointer;
}

button.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.modal-patient {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
}

.modal-component {
  width: 600px;
  margin: 3em;
  padding: 2em;
  background: #ffffff;
  border-radius: 5px;

  justify-content: center;
  text-align: center;
}

.register-image-banner {
  display: flex;
  justify-content: center;
  margin: 0px 5px;
}

.form-container {
  width: 100%;
  box-sizing: border-box;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-width: 1px;
  height: 38px;
  border-radius: 10px;
}

/*for datepicker */
.container {
  max-width: 500px;
}

.form-group {
  margin-bottom: 15px !important;
  position: relative;
}

.form-control {
  height: 38px;
  border: 1px solid #ccc !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
}

.date-of-birth .form-control {
  background-image: url('./images/DLP_revamp_asset/Icon/datepicker2.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
}

.form-control::placeholder {
  color: #6c757d;
}

.date-of-birth img {
  display: none;
}

.gender-select {
  position: relative;
}

.gender-select select {
  color: #636c72;
  padding-right: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./images/DLP_revamp_asset/Icon/chevron-icon.svg');
  background-repeat: no-repeat;
  background-position: right 16px center;
  cursor: pointer;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.gender-select select option:first-child {
  color: #636c72;
}

.gender-select select:invalid {
  color: #636c72;
}

.gender-select .form-control::-ms-expand {
  display: none;
}

/*custom checkbox*/
.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-checkbox input[type='checkbox'] {
  -webkit-appearance: none !important;
  appearance: none !important;
  min-width: 20px;
  min-height: 20px;
  margin-top: 3px;
  border: 2px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

.custom-checkbox input[type='checkbox']:checked {
  background-color: #f9df81 !important;
  border-color: #f9df81 !important;
}

.custom-checkbox input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.checkbox-container-register {
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  gap: 10px;
}

.input-with-icon {
  position: relative;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.chevron-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.select-field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../client/src/images/DLP_revamp_asset/Icon/chevron-icon.svg')
    no-repeat calc(100% - 15px) center;
  background-color: white;
  background-size: 20px;
  padding-right: 30px;
  color: #999;
  position: relative;
}

::placeholder {
  color: #999;
  font-size: 0.9rem;
}

.patient-header-text {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #fff;
}

.sv_progress_bar {
  display: flex;
  justify-content: space-between;
  height: 5px;
  margin-bottom: 20px;
}

.sv_progress_bar > div {
  background-color: #d3d3d3;
  height: 100%;
  flex: 1;
  margin: 0 3px;
  position: relative;
}

.sv_progress_bar > div::before {
  content: '';
  display: block;
  height: 100%;
  background-color: #67d841; /* Progress color */
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
}

.sv_progress_bar > div.completed::before {
  width: 100%;
}

.sv_progress_bar > div.current::before {
  width: 100%;
}
.placeholder {
  color: #999;
}

/* .custom-module2-description {
  margin-top: -1rem;
} */
.sv-question__content {
  padding-bottom: 0.5rem;
}
.sv_action_description-q1 {
  text-align: left;
}

.red-error-no-answer {
  background-color: #d5290133;
}

@media only screen and (min-width: 480px) {
  .hamburger {
    font-size: 50px;
    padding-right: 20px;
    margin: 0;
  }

  .menu-icon {
    font-size: 100px;
  }

  .desktop-wrapper {
    margin: 0 5%;
  }
}

.advocate-id-div {
  display: flex;
  background-color: #0071bc;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.custom-survey-description {
  margin-top: -2rem;
}

.select-gender {
  font-size: 15px !important;
}

@media only screen and (max-width: 480px) {
  .sv-string-viewer img {
    max-width: 200px !important;
    overflow: hidden !important;
  }

  .logo-homepage {
    width: 50% !important;
  }

  /* Override image style for specific module questions */
  .sv-string-viewer img.custom-img {
    max-width: 100px !important; /* Set the desired smaller width */
    overflow: hidden !important;
  }

  .custom-modal-blue-button {
    top: 0px !important;
  }
}

@media only screen and (max-width: 340px) {
  .sv-string-viewer img {
    max-width: 80px !important;
    overflow: hidden !important;
  }
}
