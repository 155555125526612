
#react-pro-sidebar {
  height: 100vh;
  color: white;
}

.admin-page {
  display: flex;
  /* height: 100vh;
	width: 100vw; */
}

.sidebarColor {
  background-color: #1d1d1d;
}

.admin-body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.admin-content {
  max-height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
}

.form-content {
  padding: 0 15%;
}

.title-text {
  font-weight: 700;
}

/* ADMIN Login */
.login {
  background-color: #0a1a30;
  height: 100vh;
  width: 100vw;
  padding-top: 30vh;
  text-align: center;
  overflow: hidden;
}
.user-login {
  height: 100vh;
  width: 100vw;
  padding-top: 30vh;
  text-align: center;
  overflow: hidden;
}

.image-container {
  color: white;
  font-weight: bold;
  margin-right: 4rem;
  max-width: 500px;
}

.login-form {
  background-color: white;
  max-width: 500px;
  padding: 40px;
  border-radius: 5px;
}

/* main dashboard styles */
.main-cards:hover {
  transform: scale(1.03);
  transition: all 0.1s ease-in;
}

.setting-card {
  width: 50%;
}
.navbar-for-mobile {
  display: none;
}

/* side navbar for mobile styles */
.offcanvas-body ul li {
  padding: 1rem;
}

.offcanvas-body ul li:hover {
  color: rgba(208, 208, 208, 0.7);
}

ul.horizontal-fix li a {
  padding: 0.84rem 2.14rem;
}

.border-plus-btn {
  border-color: #aaa;
  cursor: pointer;
  border-style: dashed;
  border-width: 1px;
  padding-bottom: 1px;
}
/* 
tr td {
  font-weight: 300;
} */

/* account add styles */

/* lg breakpoint */
@media only screen and (max-width: 992px) {
  .setting-card {
    width: 80%;
  }
  .form-content {
    padding: 2% 5%;
  }
}

/* md breakpoint */
@media only screen and (max-width: 768px) {
  .setting-card {
    width: 80%;
  }
}

/* sm breakpoint */
@media only screen and (max-width: 576px) {
  .setting-card {
    width: 100%;
  }

  .navbar-for-mobile {
    display: block;
  }
}
